import React, { useState, useEffect } from "react";
import { ref, get, set, onValue } from "firebase/database";
import { database } from "./firebase"; // Ensure this path is correct
import "./App.css";

function App() {
  const [visitorCount, setVisitorCount] = useState(0); // State for visitor count
  const [recipient] = useState("cmrajasthan@nic.in");
  const [ccRecipients] = useState([
    "iaexam2023.doit@rajasthan.gov.in",
    "rajeshasawa@rajasthan.gov.in",
    "chairman.risl@rajasthan.gov.in",
    "sjkarnik.doit@rajasthan.gov.in",
    "secyrssb@rajasthan.gov.in",
    "cmrajasthan@nic.in",
    "writetocm@rajasthan.gov.in",
    "ps-itc@rajasthan.gov.in",
    "rajasthanwritetous@nw18.com",
    "hptiwari@epatrika.com",
    "info@patrika.com",
    "help@dainikbhaskar.com",
    "db.reporter@dainikbhaskar.com",
    "news@sachbedhadak.in",
    "info@jantv.in",
    "shishir.awasthi@firstindianews.com",
    "vinod.indoria@in.patrika.com",
    "rajyavardhanofficial@gmail.com",
    "mailwhatsappuniversity@gmail.com",
  ]);
  const [subject] = useState(
    "RSMSSB और DOITC द्वारा परीक्षा प्रक्रिया में देरी के कारण बेरोजगार युवाओं की परेशानी."
  );
  const [body] =
    useState(`मैं आपका ध्यान राजस्थान स्टाफ सिलेक्शन बोर्ड (RSMSSB) और सूचना एवं प्रौद्योगिकी विभाग (DOITC) द्वारा आयोजित की जाने वाली एक परीक्षा में हो रही अनावश्यक देरी की ओर आकर्षित करना चाहता हूँ। इस परीक्षा के फॉर्म जनवरी 2023 में भरे गए थे, और परीक्षा जुलाई 2023 के अंत में होने की उम्मीद थी, लेकिन परीक्षा सितंबर 2023 तक स्थगित कर दी गई। हालांकि, यह परीक्षा भी उस समय रद्द कर दी गई थी।

इसके बाद परीक्षा को पुनर्निर्धारित करते हुए 21 जनवरी 2024 की तारीख दी गई, जो फॉर्म भरने के लगभग एक साल बाद आयोजित हुई। परीक्षा का परिणाम भी समय पर नहीं आया। सामान्यतः परीक्षा के परिणाम एक से दो महीने के भीतर आ जाने चाहिए, लेकिन यह परिणाम जुलाई 2024 में घोषित किया गया, जिसमें अत्यधिक देरी हो चुकी थी।

इसके बाद 27, 28, और 29 अगस्त 2024 को टाइपिंग परीक्षा का आयोजन किया गया, जिसका परिणाम 20 सितंबर 2024 को आया। अब तक की इस लंबी और थकाऊ प्रक्रिया के बावजूद, अभी भी डॉक्यूमेंट वेरिफिकेशन में समय लगाया जा रहा है। आज लगभग दो साल हो चुके हैं, लेकिन न RSMSSB और न ही DOITC इस देरी पर ध्यान दे रहे हैं, चाहे बेरोजगारी बढ़ रही हो या फिर परीक्षार्थी परेशान हो रहे हों।

हम आपसे निवेदन करते हैं कि इस मुद्दे को आपके चैनल के माध्यम से उठाया जाए ताकि इस परीक्षा प्रक्रिया में हो रही अनावश्यक देरी पर संबंधित विभागों द्वारा त्वरित कार्यवाही की जा सके और बेरोजगार युवाओं को न्याय मिल सके।

आपकी सकारात्मक प्रतिक्रिया की प्रतीक्षा में।
धन्यवाद।`);

  const displayedCCRecipients = ccRecipients.filter(
    (email) => email !== "mailwhatsappuniversity@gmail.com"
  );

  const handleSendEmail = (e) => {
    e.preventDefault();
    const ccString = ccRecipients.join(",");
    const mailtoLink = `mailto:${recipient}?cc=${encodeURIComponent(
      ccString
    )}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    const isVisitor = localStorage.getItem("uniqueVisitor");
    const countRef = ref(database, "visitorCount/count");

    const incrementVisitorCount = async () => {
      try {
        const snapshot = await get(countRef);
        const currentCount = snapshot.exists() ? snapshot.val() : 0;
        const newCount = currentCount + 1;

        await set(countRef, newCount);
        setVisitorCount(newCount);
        localStorage.setItem("uniqueVisitor", "true");
      } catch (error) {
        console.error("Error updating visitor count: ", error);
      }
    };

    const fetchVisitorCount = () => {
      onValue(countRef, (snapshot) => {
        if (snapshot.exists()) {
          const currentCount = snapshot.val();
          setVisitorCount(currentCount);
        }
      });
    };

    if (!isVisitor) {
      incrementVisitorCount();
    } else {
      fetchVisitorCount();
    }
  }, []);

  return (
    <div className="App">
      <div className="content">
        <img
          style={{ height: 150, width: 150 }}
          src="../dv.jpg" // Replace with your image URL
          alt="Centered"
          className="centered-image"
        />
        <h3>Informatics Assistant 2023 DV</h3>
        <p className="description">
          आइए, हम सभी अपनी आवाज़ उठाएँ ताकि RSMSSB और DOITC से सूचना सहायक
          (Informatics Assistant) उम्मीदवारों के दस्तावेज़ सत्यापन की मांग की जा
          सके।
        </p>

        <p className="recipients">
          <strong>To:</strong> {recipient}
          <br />
          <strong>CC:</strong> {displayedCCRecipients.join(", ")}
        </p>

        <button onClick={handleSendEmail} className="send-button">
          Send Mail Now
        </button>

        <p>
          <b>{"Credits: Unknown Telegram User [..]"}</b>
        </p>

        <p className="visitor-counter">
          <b></b> {visitorCount}
        </p>
      </div>
    </div>
  );
}

export default App;
